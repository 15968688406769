import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';
import Posts from '@sections/Posts';

const PostsTemplate = ({ pageContext }) => {
  const { group } = pageContext;
  const posts = group.map((e) => e.node);
  return (
    <Layout>
      <Navbar hideMenu={true} />
      <Posts posts={posts} paginateContext={pageContext} />
      <Footer />
    </Layout>
  );
};

export default PostsTemplate;
